/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useId, Field, Combobox, Option } from "@fluentui/react-components";
import { Wrapper, OptionDetails } from "./styles.common";
import CustomLabel from "./CustomLabel";
import { changeListHeight } from "../../utils/changeStyles";
 
const ParaSelect = ({
  name,
  label,
  valueType,
  defaultValue,
  isMandatory,
  size,
  optionListKeys,
  optionList,
  selectedPara,
  setSelectedPara,
}) => {
  const selectId = useId(`para-select_${name}`);
  const paraMainKey = optionListKeys[0];
  const paraName = name;
  const targetSelectedParaObj = selectedPara[paraName];
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { value: defaultValue, isMandatory: isMandatory, type: valueType, err: null },
    }));

    let sortedOptionList;
    if (Array.isArray(optionList[0][paraMainKey])) {
      sortedOptionList = [...optionList];
    } else {
      sortedOptionList = optionList.sort((a, b) => a[paraMainKey].localeCompare(b[paraMainKey]));
    }

    setFilteredOptions(sortedOptionList);
  }, []);

  const handleChange = (e) => {
    const trimmedValue = e.target.value.trim();

    const filteredList = optionList?.filter((option) => {
      {
        for (const key of optionListKeys) {
          return option[key].toLowerCase().includes(trimmedValue.toLowerCase());
        }
      }
    });
    setFilteredOptions(filteredList);

    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { ...targetSelectedParaObj, value: trimmedValue, err: null },
    }));
    setSearchValue(trimmedValue);
  };

  const handleOptionSelect = (e, option) => {
    const textValue = option?.optionText || e.target.value;
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { ...targetSelectedParaObj, value: textValue, err: null },
    }));
    setSearchValue(textValue);
  };

  const handleOpenChange = (e, data) => {
    if (data.open) {
      if (filteredOptions.length === 0) {
        setFilteredOptions([...optionList]);
      }
      if (filteredOptions.length > 0) {
        changeListHeight("fui-Listbox", 340);
      }
    }
  };

  return (
    <Wrapper>
      <Field
        label={<CustomLabel id={selectId} label={label} />}
        required={isMandatory}
        validationState={targetSelectedParaObj?.err ? "error" : "none"}
        validationMessage={
          targetSelectedParaObj?.err === "empty"
            ? "This field cannot be empty"
            : targetSelectedParaObj?.err === "exceed"
            ? `The maximum character limit for this field is ${size}. The number of characters entered is ${targetSelectedParaObj?.value.length}`
            : null
        }
      >
        <Combobox
          aria-labelledby={selectId}
          id={selectId}
          placeholder="..."
          selectedKeys={targetSelectedParaObj?.value}
          onChange={handleChange}
          onOptionSelect={handleOptionSelect}
          onOpenChange={handleOpenChange}
          // onBlur={handleBlur}
          freeform
          size="large"
        >
          {filteredOptions.length === 0 ? (
            <Option key="search" text={searchValue} value={searchValue}>
              New value: {searchValue}
            </Option>
          ) : null}

          {/* {filteredOptions.length === 0 ? (
            <Option key="no-results" disabled>
              No items found
            </Option>
          ) : null} */}

          {filteredOptions.map((value, idx) => {
            if (Array.isArray(value[paraMainKey])) {
              return (
                <Option
                  key={`select_${name}_option-${value[paraMainKey][0]}-${idx}`}
                  value={value[paraMainKey][0]}
                  text={value[paraMainKey][0]}
                >
                  <OptionDetails>
                    {value[paraMainKey].map((key, idx) => (
                      <p key={`option_${value[paraMainKey][0]}_${key}-${idx}`}>{key}</p>
                    ))}
                  </OptionDetails>
                </Option>
              );
            } else {
              return (
                <Option
                  key={`select_${name}_option-${value[paraMainKey]}-${idx}`}
                  value={value[paraMainKey]}
                  text={value[paraMainKey]}
                >
                  <OptionDetails>
                    {optionListKeys.map((key, idx) => (
                      <p key={`option_${value[paraMainKey]}_${value[key]}-${idx}`}>{value[key]}</p>
                    ))}
                  </OptionDetails>
                </Option>
              );
            }
          })}
        </Combobox>
      </Field>
    </Wrapper>
  );
};

ParaSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  valueType: PropTypes.string,
  defaultValue: PropTypes.string,
  isMandatory: PropTypes.bool,
  size: PropTypes.number,
  optionListKeys: PropTypes.array,
  optionList: PropTypes.array,
  selectedPara: PropTypes.object,
  setSelectedPara: PropTypes.func,
};

export default ParaSelect;
