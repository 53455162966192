/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import {
  useToastController,
  Divider,
  Toast,
  ToastTitle,
  ToastTrigger,
  ToastBody,
  Text,
  ToastFooter,
  Toaster,
  Link,
  Button,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import styled from "styled-components";
import { Wrapper, FieldsWrapper } from "./styles.common";
import { Col } from "../Fields/styles.common";
import Loader from "../Fields/Loader";
import Select from "../Fields/Select";
import ParaSelect from "../Fields/ParaSelect";
import ParaMultiselect from "../Fields/ParaMultiselect";
import FreeText from "../Fields/FreeText";
import CheckboxSquare from "../Fields/CheckboxSquare";
import Buttons from "../Buttons";
import ParaDate from "../Fields/ParaDate";
import Message from "../Message";
import { loadInstantReport } from "../../officeDocuments/report-document";
import { fetchIntRptListData, fetchIntRptParaData, loadIntRptData } from "../../utils/apiCalls";
import { multiselectJoinedChar } from "../../utils/separators";
import { ClientContext } from "../App";

// import Preview from "../Preview";

const ParaWrapper = styled.div`
  padding: 20px 0 12px;
`;

const InstantReport = () => {
  const clientInfo = useContext(ClientContext);

  const [isRptListLoad, setRptListLoad] = useState(true);
  const [intRptListLoading, setIntRptListLoading] = useState(false);
  const [intRptList, setIntRptList] = useState([]);
  const [intRptListErr, setIntRptListErr] = useState(false);
  const [selectedIntRpt, setSelectedIntRpt] = useState({ displayName: "", storeName: "", err: null });

  const [isParaListLoad, setParaListLoad] = useState(false);
  const [paraListLoading, setParaListLoading] = useState(false);
  const [paraList, setParaList] = useState([]);
  const [paraListErr, setParaListErr] = useState(false);
  const [selectedPara, setSelectedPara] = useState({});

  const [intRptLoading, setIntRptLoading] = useState(false);
  const [intRptSuc, setIntRptSuc] = useState(false);
  const [intRptErr, setIntRptErr] = useState(false);
  const [isIntRptEmpty, setIntRptEmpty] = useState(false);
  const [toastIntent, setToastIntent] = useState(null);

  const [toasterId, setToastId] = useState(crypto.randomUUID());
  const { dispatchToast } = useToastController(toasterId);

  const getIntRptList = async () => {
    try {
      setIntRptListErr(false);
      setIntRptListLoading(true);
      setIntRptList([]);

      const rptData = await fetchIntRptListData();

      setIntRptList(rptData);
      setIntRptListLoading(false);
    } catch (err) {
      setIntRptListLoading(false);
      setIntRptList([]);
      setIntRptListErr(err);
    }
  };

  const getIntRptPara = async (selectedIntRpt) => {
    try {
      setParaListErr(false);
      setParaListLoading(true);
      setParaList([]);

      const paraData = await fetchIntRptParaData(selectedIntRpt);

      setParaList(paraData);
      setParaListLoading(false);
    } catch (err) {
      setParaListLoading(false);
      setParaList([]);
      setParaListErr(err);
    }
  };

  const loadReport = async (selectedIntRptName, paraArr) => {
    try {
      setIntRptErr(false);
      setIntRptSuc(false);
      setIntRptLoading(true);
      setIntRptEmpty(false);

      const data = await loadIntRptData(selectedIntRptName, paraArr);
      loadInstantReport(data);

      if (data.length === 0) {
        setIntRptEmpty(true);
      } else {
        setIntRptEmpty(false);
      }

      setIntRptSuc(true);
      setIntRptLoading(false);
    } catch (err) {
      setIntRptLoading(false);
      setIntRptSuc(false);
      setIntRptEmpty(false);
      setIntRptErr(err);
    }
  };

  useEffect(() => {
    if (isRptListLoad) {
      setRptListLoad(false);
      getIntRptList();
    }
  }, [isRptListLoad]);

  useEffect(() => {
    setSelectedPara({});
    setParaList([]);

    if (selectedIntRpt.storeName?.length > 0 && isParaListLoad) {
      setParaListLoad(false);
      getIntRptPara(selectedIntRpt.storeName);
    }
  }, [selectedIntRpt.storeName, isParaListLoad]);

  const handleIntRptChange = (name, value) => {
    setSelectedIntRpt({ displayName: name, storeName: value, err: null });
    setParaListLoad(true);
  };

  const handleClearClick = () => {
    console.log("clear");
  };

  const handleLoadClick = () => {
    if (!checkValid()) return;
    let paraArr = [];
    for (const key in selectedPara) {
      if (selectedPara[key].value?.length > 0 || selectedPara[key].value === 1) {
        if (selectedPara[key].type === "multiselect") {
          const encodedArr = selectedPara[key].value.map((value) => encodeURIComponent(value));
          const str = encodedArr.join(multiselectJoinedChar);
          paraArr.push(`${key}='${str}'`);
        } else {
          paraArr.push(`${key}='${encodeURIComponent(selectedPara[key].value)}'`);
        }
      }
    }
    loadReport(selectedIntRpt.storeName, paraArr);
  };

  const checkValid = () => {
    let valid = true;

    if (selectedIntRpt.err) {
      valid = false;
      return;
    }

    if (selectedIntRpt?.storeName.length === 0) {
      setSelectedIntRpt({ name: "", err: "empty" });
      valid = false;
    }

    setSelectedPara((prev) => {
      const copiedObj = { ...prev };
      for (const key in copiedObj) {
        if (copiedObj[key].isMandatory) {
          if (copiedObj[key].value?.length === 0 || copiedObj[key].value === 0) {
            copiedObj[key].err = "empty";
            valid = false;
          }
        }
      }
      return copiedObj;
    });

    return valid;
  };

  useEffect(() => {
    if (intRptSuc) {
      if (isIntRptEmpty) {
        setToastIntent("warning");
      } else {
        setToastIntent("success");
      }
      setToastId(crypto.randomUUID());
      return;
    }

    if (intRptErr) {
      setToastIntent("error");
      setToastId(crypto.randomUUID());
      return;
    }
  }, [isIntRptEmpty, intRptSuc, intRptErr]);

  useEffect(() => {
    if (toastIntent) {
      dispatchToast(
        <Toast>
          {intRptSuc && (
            <>
              <ToastTitle
                action={
                  <ToastTrigger>
                    <Button aria-label="dismiss" appearance="transparent" icon={<DismissRegular />} />
                  </ToastTrigger>
                }
              >
                {isIntRptEmpty ? "Empty Report" : " Report Load Successfully"}
              </ToastTitle>
              {isIntRptEmpty && (
                <ToastBody>
                  <Text>The report is empty. No data available.</Text>
                </ToastBody>
              )}
            </>
          )}

          {intRptErr && (
            <>
              <ToastTitle
                action={
                  <ToastTrigger>
                    <Button aria-label="dismiss" appearance="transparent" icon={<DismissRegular />} />
                  </ToastTrigger>
                }
              >
                Error
              </ToastTitle>
              <ToastBody>
                <Text>Please try again. If the issue persists, please contact us for further assistance.</Text>
              </ToastBody>
              <ToastFooter>
                <Link href={`mailto:${clientInfo.contact}`}>Contact us</Link>
              </ToastFooter>
            </>
          )}
        </Toast>,
        { position: "top", intent: toastIntent }
      );
      setToastIntent(null);
    }
  }, [toastIntent]);

  return (
    <Wrapper>
      <FieldsWrapper>
        {intRptListLoading ? (
          <Col>
            <Loader />
          </Col>
        ) : intRptListErr ? (
          <Col>
            <Message
              type="error"
              title="Error"
              msgType="loadErr"
              isMainBtn={true}
              mainBtnTxt="Reload"
              handleMainBtnClick={() => setRptListLoad(true)}
            />
          </Col>
        ) : (
          <Select
            name="intRpt"
            label="Report name"
            isMandatory={true}
            isDisabled={false}
            value={selectedIntRpt.displayName}
            err={selectedIntRpt.err}
            optionList={intRptList}
            nameDisplay="SPDESC"
            nameStore="USERACCESSSP"
            selectedIntRpt={selectedIntRpt}
            handleIntRptChange={handleIntRptChange}
          />
        )}

        {!paraListLoading && paraList?.length > 0 && <Divider>Parameters</Divider>}
        <ParaWrapper>
          {paraListErr ? (
            <Col>
              <Message
                type="error"
                title="Error"
                msgType="loadErr"
                isMainBtn={true}
                mainBtnTxt="Reload"
                handleMainBtnClick={() => setParaListLoad(true)}
              />
            </Col>
          ) : (
            paraList?.length > 0 &&
            paraList.map(
              ({ paraName, paraLabel, paraDefault, isParaMandatory, valueType, paraSize, paraKeys, valueList }, idx) =>
                valueType === "select" ? (
                  <ParaSelect
                    key={`${paraName}-${idx}`}
                    name={paraName}
                    label={paraLabel}
                    valueType={valueType}
                    defaultValue={paraDefault}
                    isMandatory={isParaMandatory}
                    // isMandatory={true}
                    size={paraSize}
                    optionListKeys={paraKeys}
                    optionList={valueList}
                    selectedPara={selectedPara}
                    setSelectedPara={setSelectedPara}
                  />
                ) : valueType === "date" ? (
                  <ParaDate
                    key={`${paraName}-${idx}`}
                    name={paraName}
                    label={paraLabel}
                    valueType={valueType}
                    isMandatory={isParaMandatory}
                    // isMandatory={true}
                    selectedPara={selectedPara}
                    setSelectedPara={setSelectedPara}
                  />
                ) : valueType === "checkbox" ? (
                  <CheckboxSquare
                    key={`${paraName}-${idx}`}
                    name={paraName}
                    label={paraLabel}
                    valueType={valueType}
                    defaultValue={paraDefault}
                    isMandatory={isParaMandatory}
                    // isMandatory={true}
                    selectedPara={selectedPara}
                    setSelectedPara={setSelectedPara}
                  />
                ) : valueType === "freetext" ? (
                  <FreeText
                    key={`${paraName}-${idx}`}
                    name={paraName}
                    label={paraLabel}
                    valueType={valueType}
                    defaultValue={paraDefault}
                    isMandatory={isParaMandatory}
                    // isMandatory={true}
                    selectedPara={selectedPara}
                    setSelectedPara={setSelectedPara}
                  />
                ) : valueType === "multiselect" ? (
                  <ParaMultiselect
                    key={`${paraName}-${idx}`}
                    name={paraName}
                    label={paraLabel}
                    valueType={valueType}
                    // defaultValue={paraDefault}
                    isMandatory={isParaMandatory}
                    // isMandatory={true}
                    optionListKeys={paraKeys}
                    optionList={valueList}
                    selectedPara={selectedPara}
                    setSelectedPara={setSelectedPara}
                  />
                ) : null
            )
          )}
        </ParaWrapper>
        {/*{Object.keys(selectedPara).length > 0 && (*/}
        {/*  <Preview rptName={selectedIntRpt.displayName} selectedPara={selectedPara} />*/}
        {/*)}*/}
      </FieldsWrapper>

      {!intRptListLoading && !intRptListErr && !paraListErr && (
        <Buttons
          mainBtnTxt="Load Report"
          secondaryBtnTxt="Clear"
          isMainBtnDisabled={intRptLoading}
          isSecondaryBtnDisabled={false}
          handleConfirmClick={handleLoadClick}
          handleCancelClick={handleClearClick}
        />
      )}
      <Toaster toasterId={toasterId} />
    </Wrapper>
  );
};

export default InstantReport;
