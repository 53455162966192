/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { makeStyles, tokens, Button } from "@fluentui/react-components";

const Wrapper = styled.div`
  justify-self: end;
  display: flex;
  align-items: center;
  gap: 12px;

  button {
    width: 130px;
  }
`;
//
const useStyles = makeStyles({
  disabled: {
    cursor: "not-allowed !important",
    backgroundColor: tokens.colorBrandBackgroundHover,
  },
});

const Buttons = ({
  mainBtnTxt,
  secondaryBtnTxt,
  isMainBtnDisabled,
  isSecondaryBtnDisabled,
  handleCancelClick,
  handleConfirmClick,
}) => {
  const style = useStyles();

  return (
    <Wrapper>
      {/* <Button
        appearance="secondary"
        size="large"
        as="button"
        onClick={handleCancelClick}
        disabled={isSecondaryBtnDisabled}
      >
        {secondaryBtnTxt}
      </Button> */}
      <Button
        className={isMainBtnDisabled ? style.disabled : ""}
        appearance="primary"
        size="large"
        as="button"
        onClick={() => {
          if (!isMainBtnDisabled) {
            handleConfirmClick();
          }
        }}
      >
        {mainBtnTxt}
      </Button>
    </Wrapper>
  );
};

Buttons.propTypes = {
  mainBtnTxt: PropTypes.string,
  secondaryBtnTxt: PropTypes.string,
  isMainBtnDisabled: PropTypes.bool,
  isSecondaryBtnDisabled: PropTypes.bool,
  handleCancelClick: PropTypes.func,
  handleConfirmClick: PropTypes.func,
};

export default Buttons;
