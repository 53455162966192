/* eslint-disable */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Checkbox, Field } from "@fluentui/react-components";
import { Wrapper } from "./styles.common";

const CheckboxSquare = ({ name, label, valueType, defaultValue, isMandatory, selectedPara, setSelectedPara }) => {
  const numDefaultValue = +defaultValue;
  const paraName = name;
  const targetSelectedParaObj = selectedPara[paraName];

  useEffect(() => {
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: {
        value: numDefaultValue ? numDefaultValue : 0,
        isMandatory: isMandatory,
        type: valueType,
        err: null,
      },
    }));
  }, []);

  const handleChange = (e) => {
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { ...targetSelectedParaObj, value: targetSelectedParaObj.value === 1 ? 0 : 1, err: null },
    }));
  };

  return (
    <Wrapper className="wrapper-checkbox">
      <Field
        required={isMandatory}
        validationState={targetSelectedParaObj?.err ? "error" : "none"}
        validationMessage={targetSelectedParaObj?.err === "empty" ? "This field should be checked" : null}
      >
        <Checkbox label={label} checked={targetSelectedParaObj?.value ? 1 : numDefaultValue} onChange={handleChange} />
      </Field>
    </Wrapper>
  );
};

CheckboxSquare.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  valueType: PropTypes.string,
  defaultValue: PropTypes.string,
  isMandatory: PropTypes.bool,
  selectedPara: PropTypes.object,
  setSelectedPara: PropTypes.func,
};

export default CheckboxSquare;
