/* eslint-disable */

const colors = {
  HEADER_GREEN: "#96F050",
  HEADER_BORDER: "#A2E4B8",
  FILL: "#d9e1f2",
  BLACK_FILL: "#000000",
  BLUE_FILL: "#bdd7ee",
  GREEN_FILL: "#c6efce",
  YELLOW_FILL: "#ffeb9c",
  ORANGE_FILL: "#ffd966",
  RED_FILL: "#ffc7ce",
  GREY_FILL: "#e8e8e8",
  VERTICAL_SEPARATOR_FILL: "#000080",
};

//always set all the properties even if they won't be used (like bold=false)
const rptInst = {
  metaData: {
    name: {
      range: "B1",
      font: {
        bold: true,
        size: 20,
      },
    },
    description: {
      range: "B2",
      font: {
        bold: false,
        size: 12,
      },
    },
    info: {
      range: "B3",
      font: {
        bold: false,
        size: 8,
      },
    },
    parameters: {
      range: "B4",
      font: {
        bold: false,
        size: 8,
      },
    },
  },
  header: {
    color: colors.HEADER_GREEN,
    font: {
      bold: true,
      size: 8,
      color: "#000000",
    },
    alignment: {
      horizontalAlignment: Excel.HorizontalAlignment.center,
      verticalAlignment: Excel.VerticalAlignment.center,
    },
    border: {
      outline: [
        Excel.BorderIndex.edgeRight,
        Excel.BorderIndex.edgeLeft,
        Excel.BorderIndex.edgeTop,
        Excel.BorderIndex.edgeBottom,
      ],
      color: colors.HEADER_BORDER,
    },
  },
  body: {
    row: {
      height: 20,
    },
    border: {
      outline: [
        Excel.BorderIndex.edgeTop,
        Excel.BorderIndex.edgeRight,
        Excel.BorderIndex.edgeBottom,
        Excel.BorderIndex.edgeLeft,
        Excel.BorderIndex.insideVertical,
        Excel.BorderIndex.insideHorizontal,
      ],
      style: "Dot",
      color: colors.HEADER_GREEN,
    },
  },
};

const columnWidth = {
  70: 371,
  50: 266.12,
  40: 214,
  35: 187.75,
  30: 161.54,
  25: 135.21,
  24: 129.89,
  22: 119.11,
  20: 108.81,
  18: 98,
  17: 92.89,
  16: 88,
  15: 82.24,
  14: 77.52,
  13: 72.21,
  12: 67,
  11: 62,
  10: 56.26,
  9: 51,
  8: 46,
  7: 42.10,
  6: 35.21,
  5: 30,
  3: 19.5,
  2: 14,
  "#SEP#": 4.74,
};

export { colors, rptInst, columnWidth };
