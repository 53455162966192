import React from "react";
import PropTypes from "prop-types";
import { Image } from "@fluentui/react-components";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    position: absolute;
    top: 12px;
    left: 12px;
  }
`;

const Header = ({ clientLogo, productLogo }) => {
  return (
    <Wrapper>
      {clientLogo && <Image className="logo" width="24" src={clientLogo} alt="logo" />}
      {productLogo && <Image className="title" height="18" src={productLogo} alt="product-logo" />}
    </Wrapper>
  );
};

Header.propTypes = {
  clientLogo: PropTypes.string,
  productLogo: PropTypes.string,
};

export default Header;
