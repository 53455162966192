import styled from "styled-components";

export const Col = styled.div`
  margin-bottom: 12px;
`;

export const Wrapper = styled.div`
  /* max-width: 400px; */
  height: 90px;
  margin-bottom: 12px;

  &.wrapper-checkbox {
    height: 55px;
  }

  .fui-Field {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    justify-items: start;
    row-gap: 2px;
  }

  .fui-Input,
  .fui-Combobox {
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 10px;
  }

  label {
    margin-bottom: 1px;
  }

  input {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const OptionDetails = styled.div`
  padding: 2px 0;

  p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 500;
  }

  p:first-of-type {
    font-size: 15px;
    font-weight: 400;
  }
`;
