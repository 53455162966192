import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FluentProvider } from "@fluentui/react-components";
import styled from "styled-components";
import Initial from "./Initial";
import MainContent from "./MainContent";
import Footer from "./Footer";
import { lightTheme, darkTheme } from "../config/theme";
import { fetchMainClientData } from "../utils/apiCalls";
import { defaultContactLink } from "../utils/link";
import LoadSpinner from "./LoadSpinner";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

const LoadSpinnerWrapper = styled.div`
  height: 100%;
  display: grid;
  align-items: center;
`;

export const ClientContext = createContext({});

const App = () => {
  const [theme, setTheme] = useState("light");
  const [isAuth, setAuth] = useState(false);
  const [selectedValue, setSelectedValue] = useState("loadReport");

  const [isClientInfoLoading, setClientInfoLoading] = useState(true);
  const [clientInfoErr, setClientInfoErr] = useState(false);
  const [clientInfo, setClientInfo] = useState({
    clientLogo: null,
    productLogo: null,
    title: "Add-In",
    contact: defaultContactLink,
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    (async () => {
      try {
        setClientInfoLoading(true);
        setClientInfoErr(false);

        const data = await fetchMainClientData(signal);

        if (data) {
          setClientInfo({
            clientLogo: data.logo,
            productLogo: data.productName,
            title: data.title,
            contact: data.contact,
          });
        } else {
          setClientInfoErr(true);
        }

        setClientInfoLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          setClientInfoErr(true);
          setClientInfoLoading(false);
          setClientInfo({ clientLogo: null, productLogo: null, title: "Add-In", contact: defaultContactLink });
        }
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <FluentProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <ClientContext.Provider value={clientInfo}>
        <Wrapper>
          {isClientInfoLoading ? (
            <LoadSpinnerWrapper>
              <LoadSpinner />
            </LoadSpinnerWrapper>
          ) : (
            <>
              {!isAuth ? (
                <Initial setAuth={setAuth} />
              ) : (
                <MainContent selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
              )}
            </>
          )}
        </Wrapper>
        <Footer theme={theme} setTheme={setTheme} />
      </ClientContext.Provider>
    </FluentProvider>
  );
};

App.propTypes = {
  title: PropTypes.string,
};

export default App;
