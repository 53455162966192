import React from "react";
import PropTypes from "prop-types";
import { Label } from "@fluentui/react-components";

const CustomLabel = ({ id, label }) => (
  <Label htmlFor={id} weight="semibold">
    {label}
  </Label>
);

CustomLabel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};

export default CustomLabel;
