import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { makeStyles, tokens, Tab, TabList } from "@fluentui/react-components";
import { DocumentTable24Regular, DataUsage24Regular, Mail24Regular } from "@fluentui/react-icons";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
`;

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: tokens.colorNeutralStroke1,
  },
});

const MainTabs = ({ selectedValue, setSelectedValue }) => {
  const styles = useStyles();

  const onTabSelect = (event, data) => {
    setSelectedValue(data.value);
  };

  const tabList = [
    {
      id: "main-tab_load-table",
      icon: <DocumentTable24Regular />,
      value: "loadTable",
      txt: "Load Table",
      disabled: true,
    },
    {
      id: "main-tab_load-report",
      icon: <DataUsage24Regular />,
      value: "loadReport",
      txt: "Load Report",
      disabled: false,
    },
    {
      id: "main-tab_mailing",
      icon: <Mail24Regular />,
      value: "mailing",
      txt: "Mailing",
      disabled: true,
    },
  ];

  return (
    <Wrapper className={styles.wrapper}>
      <TabList className="tab-list" selectedValue={selectedValue} onTabSelect={onTabSelect}>
        {tabList.map(({ id, icon, value, txt, disabled }) => (
          <Tab key={id} icon={icon} value={value} disabled={disabled} className="tab">
            {txt}
          </Tab>
        ))}
      </TabList>
    </Wrapper>
  );
};

MainTabs.propTypes = {
  selectedValue: PropTypes.string,
  setSelectedValue: PropTypes.func,
};

export default MainTabs;
