import styled from "styled-components";

export const Wrapper = styled.form`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr max-content;
`;

export const FieldsWrapper = styled.div`
  width: 100%;
`;
