/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useId, Field, Combobox, Option } from "@fluentui/react-components";
import { Wrapper, OptionDetails } from "./styles.common";
import CustomLabel from "./CustomLabel";
import { changeListHeight } from "../../utils/changeStyles";

const ParaMultiselect = ({
  name,
  label,
  valueType,
  // defaultValue,
  isMandatory,
  optionListKeys,
  optionList,
  selectedPara,
  setSelectedPara,
}) => {
  const selectId = useId(`para-multiselect_${name}`);
  const paraMainKey = optionListKeys[0];
  const paraName = name;
  const targetSelectedParaObj = selectedPara[paraName];
  const [filteredOptions, setFilteredOptions] = useState([...optionList]);
  const [value, setValue] = useState("");

  useEffect(() => {
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { value: [], isMandatory: isMandatory, type: valueType, err: null },
    }));
  }, []);

  const handleChange = (e) => {
    const trimmedValue = e.target.value.trim();
    const filteredList = optionList?.filter((option) => {
      {
        for (const key of optionListKeys) {
          return option[key].toLowerCase().includes(trimmedValue.toLowerCase());
        }
      }
    });
    setFilteredOptions(filteredList);
    setValue(trimmedValue);
  };

  const handleOptionSelect = (e, data) => {
    const value = data?.selectedOptions || [];

    // update value on input change
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { ...targetSelectedParaObj, value: value, err: null },
    }));

    // reset value to an empty string after selection
    setValue("");
  };

  const handleOpenChange = (e, data) => {
    if (data.open) {
      if (filteredOptions.length > 0) {
        changeListHeight("fui-Listbox", 340);
      }
    }
  };

  // clear value on focus
  const handleFocus = () => {
    setValue("");
    setFilteredOptions([...optionList]);
  };

  const handleBlur = () => {
    setValue(targetSelectedParaObj.value.join(", "));
  };

  return (
    <Wrapper>
      <Field
        label={<CustomLabel id={selectId} label={label} />}
        required={isMandatory}
        validationState={targetSelectedParaObj?.err ? "error" : "none"}
        validationMessage={targetSelectedParaObj?.err === "empty" ? "This field cannot be empty" : null}
      >
        <Combobox
          aria-labelledby={selectId}
          id={selectId}
          placeholder={"..."}
          value={value}
          // selectedKeys={targetSelectedParaObj?.value}
          onChange={handleChange}
          onOptionSelect={handleOptionSelect}
          onOpenChange={handleOpenChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          multiselect
          size="large"
        >
          {filteredOptions.map((value, idx) => (
            <Option
              key={`select_${name}_option-${value[paraMainKey]}-${idx}`}
              value={value[paraMainKey]}
              text={value[paraMainKey]}
            >
              <OptionDetails>
                {optionListKeys.map((key) => (
                  <p key={`option_${value[paraMainKey]}_${value[key]}-${idx}`}>{value[key]}</p>
                ))}
              </OptionDetails>
            </Option>
          ))}

          {filteredOptions.length === 0 ? (
            <Option key="no-results" disabled>
              No items found
            </Option>
          ) : null}
        </Combobox>
      </Field>
    </Wrapper>
  );
};

ParaMultiselect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  valueType: PropTypes.string,
  // defaultValue: PropTypes.string,
  isMandatory: PropTypes.bool,
  optionListKeys: PropTypes.array,
  optionList: PropTypes.array,
  selectedPara: PropTypes.object,
  setSelectedPara: PropTypes.func,
};

export default ParaMultiselect;
