import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MainTabs from "./Nav/MainTabs";
import LoadTable from "./Modules/LoadTable";
import InstantReport from "./Modules/InstantReport";
import Mailing from "./Modules/Mailing";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: max-content 1fr;
  padding-top: 10px;

  p {
    margin: 0;
    padding: 0;
  }

  .fui-DatePicker__popupSurface,
  .fui-CalendarPicker__buttonRow,
  .fui-CalendarPicker__itemButton {
    font-family: "Segoe UI", "sans-serif" !important;
  }

  .fui-CalendarDayGrid__dayButton {
    font-family: "Segoe UI", "sans-serif" !important;
  }
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 20px 72px;
`;

const MainContent = ({ selectedValue, setSelectedValue }) => {
  return (
    <Wrapper>
      <MainTabs selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
      <Inner>
        {selectedValue === "loadTable" && <LoadTable />}
        {selectedValue === "loadReport" && <InstantReport />}
        {selectedValue === "mailing" && <Mailing />}
      </Inner>
    </Wrapper>
  );
};

MainContent.propTypes = {
  selectedValue: PropTypes.string,
  setSelectedValue: PropTypes.func,
};

export default MainContent;
