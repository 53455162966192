/* eslint-disable */
/* global Excel console */
import {columnWidth, rptInst} from "../config/excelConfig";
import {getReportStyle} from "../utils/apiCalls"


/**
 * @param rgbString {string} RGB(int R,int B,int B)
 * @example extractRGBValues('RGB(187,241,241) FILL') returns #BBF1F1
 * @returns {string} \#BBF1F1
 */
function extractRGBValues(rgbString) {
  const matches = rgbString.match(/RGB\((\d+),\s*(\d+),\s*(\d+)\)/);

  if (matches && matches.length === 4) {
    const r = parseInt(matches[1]);
    const g = parseInt(matches[2]);
    const b = parseInt(matches[3]);

    return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1).toUpperCase()}`;

  } else {
    return null;
  }
}

/**
 * @param vbaHexString {string} &HRRGGBB
 * @ &HAABBCC
 * @returns {string} #CCBBAA
 */
function parseVBAHEX(vbaHexString){
  if (typeof vbaHexString !== "string")
    return;
  let modified = vbaHexString.substring(2);
  return '#'+modified.substring(4, 6)+modified.substring(2, 4)+modified.substring(0, 2);
}


async function getDefaultStyle() {
  let resp = await getReportStyle();
  let mappedResp = {};
  for (let i=0;i<resp[0].length;i++){
    mappedResp[resp[0][i]['PropertyName']] = parseVBAHEX(resp[0][i]['StrPropertyValue']);
  }
  return mappedResp;
}

function rgbToHex(r, g, b) {
  const toHex = (value) => {
    const hex = value.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  const hexR = toHex(r);
  const hexG = toHex(g);
  const hexB = toHex(b);

  return `#${hexR}${hexG}${hexB}`;
}


function parseVBAString(input, header=false) {
  const result = {};

  const regexFontBold = /Font\.Bold\s*=\s*(\w+)/;
  const regexHorizontalAlignment = /HorizontalAlignment\s*=\s*(xl\w+)/;
  const regexNumberFormat = /NumberFormat\s*=\s*([^#]+)###|NumberFormat\s*=\s*(.+)/;
  const regexInteriorColor = /Interior\.Color\s*=\s*RGB\((\d+),\s*(\d+),\s*(\d+)\)/;

  const matchInteriorColor = input.match(regexInteriorColor);
  if (matchInteriorColor) {
    input = input.replace(regexInteriorColor, '');
    input = input.replace('###.', '');
    const rgbColor = { r: parseInt(matchInteriorColor[1]), g: parseInt(matchInteriorColor[2]), b: parseInt(matchInteriorColor[3]) };
    //header darker
    if (header === true){
      rgbColor.r = Math.round(Math.pow(Math.pow(parseFloat(matchInteriorColor[1]), 2.2) * 0.85, 1 / 2.2))
      rgbColor.g = Math.round(Math.pow(Math.pow(parseFloat(matchInteriorColor[2]), 2.2) * 0.85, 1 / 2.2))
      rgbColor.b = Math.round(Math.pow(Math.pow(parseFloat(matchInteriorColor[3]), 2.2) * 0.85, 1 / 2.2))
    }
    result.interiorColor = rgbToHex(rgbColor.r, rgbColor.g, rgbColor.b);
  }
  const matchHorizontalAlignment = input.match(regexHorizontalAlignment);
  if (matchHorizontalAlignment){
    input = input.replace(regexHorizontalAlignment, '');
    input = input.replace('###.', '');
    result.horizontalAlignment = matchHorizontalAlignment[1];
  }
  const matchFontBold = input.match(regexFontBold);
  if (matchFontBold){
    input = input.replace(regexFontBold, '');
    input = input.replace('###.', '');
    result.fontBold = matchFontBold[1] === 'True';
  }

  const matchNumberFormat = input.match(regexNumberFormat);

  if (matchNumberFormat) result.numberFormat = (matchNumberFormat[1] || matchNumberFormat[2]).trim();



  return result;
}

export const loadInstantReport = async (report) => {
  if (report.length === 0) return;
  try {
    Excel.run(async (context) => {
      OfficeExtension.config.debugInfo = true;
      const worksheet = context.workbook.worksheets.getActiveWorksheet();
      worksheet.getUsedRange().clear(Excel.ClearApplyTo.all);
      worksheet.getUsedRange().format.rowHeight=20;
      worksheet.getRange("A1").format.columnWidth=columnWidth[3];
      worksheet.showGridlines=false;
      const response = report;
      const responseKeys = Object.keys(response[0]);
      const epKey = responseKeys[0];
      //insert metadata
      //B1:B4 or B1:B3
      let metadataRange = 'B1:B';
      let metadataRangeNumber = 4;
      if (response[3][epKey] === '#HEADER#'){
        metadataRangeNumber = 3;
      }
      metadataRange += metadataRangeNumber;
      worksheet.freezePanes.freezeRows(metadataRangeNumber+1);
      const metaDataValues=[];
      for (let i=0;i<metadataRangeNumber;i++){
        metaDataValues.push([response[i][responseKeys[1]]]);
      }
      worksheet.getRange(metadataRange).values = metaDataValues;

      //format metadata
      //B1:B4 and clear B4 if not needed
      for (const metaDataKey in rptInst.metaData) {
        worksheet.getRange(rptInst.metaData[metaDataKey].range).format.font.bold = rptInst.metaData[metaDataKey].font.bold;
        worksheet.getRange(rptInst.metaData[metaDataKey].range).format.font.size = rptInst.metaData[metaDataKey].font.size;
      }
      if (metadataRangeNumber===3)
        worksheet.getRange("B4").clear(Excel.ClearApplyTo.all);

      //=====TABLE=====
      //1. Create table
      //2. Insert data
      //3. Set default format
      //4. Set individual report format

      let headerRow = Object.values(response[metadataRangeNumber]);
      let underScoresIndex = 1;
      headerRow.forEach((value, index) => {
        if (value === '__'){
          headerRow[index]=' '.repeat(underScoresIndex);
          underScoresIndex++;
        }
      });

      const colSizes = Object.values(response[metadataRangeNumber + 1])[0].includes('COLSIZE')
          ? Object.values(response[metadataRangeNumber + 1])
          : Object.values(response[metadataRangeNumber + 2]);

      const formats = Object.values(response[metadataRangeNumber + 1])[0].includes('COLSIZE')
          ? Object.values(response[metadataRangeNumber + 2])
          : Object.values(response[metadataRangeNumber + 1]);

      colSizes.shift();
      formats.shift();
      headerRow.shift();

      let rowFormatting = [];
      let dataArray = response.slice(metadataRangeNumber + 3, response.length);
      dataArray.forEach(obj => {
        if (epKey) {
          rowFormatting.push(obj[epKey]);
          delete obj[epKey];
        }
      });

      for (let i=0;i<dataArray.length;i++)
        dataArray[i] = Object.values(dataArray[i]);

      dataArray.unshift(headerRow);
      const tableRange = worksheet.getRange(`B${metadataRangeNumber+1}`).getResizedRange(dataArray.length-1, Object.keys(dataArray[0]).length-1);
      tableRange.values=dataArray;


      const table = worksheet.tables.add(tableRange, true);

      //styles
      let defaultStyles = await getDefaultStyle();
      table.getRange().format.fill.clear();
      table.getDataBodyRange().format.fill.color=defaultStyles['InteriorColorMainSheet'];
      table.getDataBodyRange().format.font.color=defaultStyles['InteriorColorBodyTxt'];
      table.getDataBodyRange().format.rowHeight=20;
      table.getDataBodyRange().format.font.size=8;

      for (let border of rptInst.body.border.outline){
        table.getDataBodyRange().format.borders.getItem(border).color = defaultStyles['InteriorColorIRBorder'];
        table.getDataBodyRange().format.borders.getItem(border).style = Excel.BorderLineStyle.dot;
      }

      //header style
      table.getHeaderRowRange().format.fill.color=defaultStyles['InteriorColorIRHeader'];
      table.getHeaderRowRange().format.rowHeight=30;
      table.getHeaderRowRange().format.font.color=defaultStyles['InteriorColorHeaderTxt'];
      table.getHeaderRowRange().format.wrapText = true;
      table.getHeaderRowRange().format.horizontalAlignment=Excel.HorizontalAlignment.center;
      table.getHeaderRowRange().format.verticalAlignment=Excel.VerticalAlignment.center;
      table.getHeaderRowRange().format.font.size=8;


      for (let border of rptInst.header.border.outline){
        table.getHeaderRowRange().format.borders.getItem(border).color = defaultStyles['InteriorColorIRBorder'];
        table.getHeaderRowRange().format.borders.getItem(border).style = Excel.BorderLineStyle.continuous;
      }


      //darker header color
      formats.forEach((value, index) => {
        if (value.includes('RGB')){
          let parsedValue = parseVBAString(value, true);
          table.getHeaderRowRange().getColumn(index).format.fill.color=parsedValue.interiorColor;
        }
      })

      //row formatting
      const colorPattern = /#([A-Z_]+)#/;
      const horizontalAlignmentPattern = /HorizontalAlignment\s*=\s*(\w+)/;
      const fontSizePattern = /\.Font\.Size\s*=\s*(\d+)/;
      const fontBoldPattern = /Font.Bold\s*=\s*(\w+)/;
      for (let i=0;i<rowFormatting.length;i++){
        if (rowFormatting[i] === '' || rowFormatting[i] === null){
          continue;
        }
        let formatValue = rowFormatting[i];
        let colorMatch = formatValue.includes('RGB')
            ? extractRGBValues(formatValue)
            : formatValue.match(colorPattern);
        let horizontalAlignmentMatch = formatValue.match(horizontalAlignmentPattern);
        let fontSizeMatch = formatValue.match(fontSizePattern);
        let fontBoldMatch = formatValue.match(fontBoldPattern);
        let result = {
          color: colorMatch ? colorMatch : 'black',
          horizontalAlignment: horizontalAlignmentMatch ? horizontalAlignmentMatch[1] : 'xlLeft',
          fontSize: fontSizeMatch ? parseInt(fontSizeMatch[1]) : 9,
          fontBold: fontBoldMatch ? fontBoldMatch[1].toLowerCase() === 'true' : false,
        };

        if (result.horizontalAlignment === 'xlLeft')
          result.horizontalAlignment=Excel.HorizontalAlignment.left;
        else if (result.horizontalAlignment === 'xlRight')
          result.horizontalAlignment=Excel.HorizontalAlignment.right;
        else
          result.horizontalAlignment=Excel.HorizontalAlignment.center;

        if (result.color.includes('GREEEN_LINE') || result.color.includes('GREEN_FILL') || result.color.includes('RP_COMMON_SUBHEADER') || result.color.includes('SUBHEADER_FILL') || result.color.includes('SUBHEADER_LINE'))
          result.color='InteriorColorIRCommonSubHeader';
        else if (result.color.includes('YELLOW_LINE') || result.color.includes('YELLOW_FILL') || result.color.includes('RP_COMMON_TITLE') || result.color.includes('TITLE_FILL') || result.color.includes('TITLE_LINE'))
          result.color='InteriorColorIRCommonTitle';
        else if (result.color.includes('BLUE_LINE') || result.color.includes('BLUE_FILL') || result.color.includes('RP_COMMON_MARK') || result.color.includes('MARK_FILL') || result.color.includes('MARK_LINE'))
          result.color='InteriorColorIRCommonMark';

        if (result.color.startsWith('#'))
          table.getDataBodyRange().getRow(i).format.fill.color=result.color;
        else
          table.getDataBodyRange().getRow(i).format.fill.color=defaultStyles[result.color];
        table.getDataBodyRange().getRow(i).format.horizontalAlignment=result.horizontalAlignment;
        table.getDataBodyRange().getRow(i).format.font.size=result.fontSize;
        table.getDataBodyRange().getRow(i).format.font.bold=result.fontBold;
      }


      //columnSizes and format needs to be the same!
      //maybe add some err handling
      //format columns #FORMAT#
      for (let i = 0; i < colSizes.length; i++) {
        // set column width
        if (Object.keys(columnWidth).includes(colSizes[i])) {
          table.getRange().getColumn(i).format.columnWidth = columnWidth[colSizes[i]];
        } else {
          console.log('Couldn\'t find column size ' + colSizes[i] + ". Using automated column size!");
          table.getRange().getColumn(i).format.columnWidth = 5.4*colSizes[i];
        }

        // set normal excel number formatting
        if (!formats[i].includes("VBA")) {
          // table.getDataBodyRange().getEntireColumn(i).numberFormat = [[formats[i]]];
          if (formats[i] === 'Text')
            table.getDataBodyRange().getColumn(i).numFmt = 'text';
          else
            table.getDataBodyRange().getColumn(i).numberFormat = formats[i];
          table.getDataBodyRange().format.horizontalAlignment = Excel.HorizontalAlignment.center;
        }

        if (formats[i].includes("VBA")) {
          let match = parseVBAString(formats[i]);
          if (match) {
            let alignment = match['horizontalAlignment'];
            let numberFormat = match['numberFormat'];
            let interiorColor = match['interiorColor'];
            let bold = match['fontBold'];

            if (alignment !== undefined) {
              if (alignment === 'xlLeft') {
                table.getDataBodyRange().getColumn(i).format.horizontalAlignment = Excel.HorizontalAlignment.left;
              } else if (alignment === 'xlRight') {
                table.getDataBodyRange().getColumn(i).format.horizontalAlignment = Excel.HorizontalAlignment.right;
              } else {
                table.getDataBodyRange().getColumn(i).format.horizontalAlignment = Excel.HorizontalAlignment.center;
              }
            }

            if (numberFormat !== undefined) {
              table.getDataBodyRange().getColumn(i).numberFormat = numberFormat;
            }
            if (interiorColor !== undefined) {
              table.getDataBodyRange().getColumn(i).format.fill.color=interiorColor;
            }
            if (bold !== null) {
              table.getDataBodyRange().getColumn(i).format.font.bold = bold;
            }
          }
        }
      }




      //fill vertical #SEP#
      Object.values(response[metadataRangeNumber+1]).forEach((value, index) => {
        if (value === '#SEP#')
          table.getRange().getColumn(index-1).format.fill.color=defaultStyles['InteriorColorIRSeparator'];
      });

      return context
        .sync()
        .then(() => console.log("Values added successfully!"))
        .catch((err) => {
          console.error("Error: " + JSON.stringify(err));
        });
    });
  } catch (error) {
    console.log("Error: " + error);
  }
};