import React, { useState } from "react";
import PropTypes from "prop-types";
import { useId, Field, Combobox, Option } from "@fluentui/react-components";
import { Wrapper } from "./styles.common";
import CustomLabel from "./CustomLabel";
import { changeListHeight } from "../../utils/changeStyles";

const Select = ({
  name,
  label,
  isMandatory,
  isDisabled,
  value,
  err,
  optionList,
  nameDisplay,
  nameStore,
  handleIntRptChange,
}) => {
  const selectId = useId(`select_${name}`);
  const [filteredOptions, setFilteredOptions] = useState([...optionList]);

  const handleChange = (e) => {
    const trimmedValue = e.target.value.trim();
    const filteredList = optionList?.filter((option) =>
      option[nameDisplay]?.toLowerCase().includes(trimmedValue?.toLowerCase())
    );
    setFilteredOptions(filteredList);
  };

  const handleOptionSelect = (e, option) => {
    const name = option?.optionText ?? "";
    const value = option?.optionValue ?? "";
    handleIntRptChange(name, value);
  };

  const handleOpenChange = (e, data) => {
    if (data.open) {
      if (filteredOptions.length === 0) {
        setFilteredOptions([...optionList]);
      }
      if (filteredOptions.length > 0) {
        changeListHeight("fui-Listbox", 500);
      }
    }
  };

  return (
    <Wrapper>
      <Field
        label={<CustomLabel id={selectId} label={label} />}
        required={isMandatory}
        validationState={err ? "error" : "none"}
        validationMessage={err === "empty" ? "This field cannot be empty" : null}
      >
        <Combobox
          aria-labelledby={selectId}
          id={selectId}
          placeholder="..."
          selectedKeys={value?.length > 0 ? value : ""}
          disabled={isDisabled}
          onChange={handleChange}
          onOptionSelect={handleOptionSelect}
          onOpenChange={handleOpenChange}
          size="large"
        >
          {filteredOptions.map((option, idx) => (
            <Option
              key={`select_${name}_option-${option[nameStore]}-${idx}`}
              value={option[nameStore]}
              text={option[nameDisplay]}
            >
              {option[nameDisplay]}
            </Option>
          ))}
          {filteredOptions.length === 0 ? (
            <Option key="no-results" disabled>
              No items found
            </Option>
          ) : null}
        </Combobox>
      </Field>
    </Wrapper>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isMandatory: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
  err: PropTypes.string,
  optionList: PropTypes.array,
  nameDisplay: PropTypes.string,
  nameStore: PropTypes.string,
  handleIntRptChange: PropTypes.func,
};

export default Select;
