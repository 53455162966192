import axios from "axios";
import { paraJoinedChar } from "./separators";

export const host = "https://ifis.upointdms.dev/api";
// export const host = "http://localhost:8080/api";

// Client 
// Get Logo, Product Name, Title, Contact
export const urlMainClientProp = `${host}/homepage`;

// Report
// Get List              => .../api/instant-reports
// Get Single Report     => .../api/instant-reports/:report
// Get Parameters        => .../api/instant-reports/:report/parameters
export const urlIntRptListGet = `${host}/instant-reports`;

export const fetchMainClientData = async (signal) => {
  const { data } = await axios.get(urlMainClientProp, await getAuthenticationHeader(), { signal });
  return data;
};

export const fetchIntRptListData = async () => {
  const { data } = await axios.get(urlIntRptListGet, await getAuthenticationHeader());
  const sortedData = data.sort((a, b) => (a.SPDESC > b.SPDESC ? 1 : b.SPDESC > a.SPDESC ? -1 : 0));
  return sortedData;
};

export const fetchIntRptParaData = async (reportName) => {
  let checkboxParaList;
  let nonCheckboxParaList;
  let sortedData = [];

  const { data } = await axios.get(`${urlIntRptListGet}/${reportName}/parameters`, await getAuthenticationHeader());

  checkboxParaList = data.filter(({ valueType }) => valueType === "checkbox");
  nonCheckboxParaList = data.filter(({ valueType }) => valueType !== "checkbox");

  if (checkboxParaList.length > 0) {
    sortedData = [...nonCheckboxParaList, ...checkboxParaList];
  } else {
    sortedData = nonCheckboxParaList;
  }

  return sortedData;
};

export const loadIntRptData = async (reportName, paraArr) => {
  if (paraArr.length === 0) {
    const { data } = await axios.get(`${urlIntRptListGet}/${reportName}`, await getAuthenticationHeader());
    return data;
  } else {
    const stringifiedParameters = paraArr.join(paraJoinedChar);
    // console.log(stringifiedParameters);
    const { data } = await axios.get(
      `${urlIntRptListGet}/${reportName}?${stringifiedParameters}`,
      await getAuthenticationHeader()
    );
    return data;
  }
};

export const getReportStyle = async () => {
  try {
    const { data } = await axios.get(`${urlIntRptListGet}/properties/styles`, await getAuthenticationHeader());
    return data;
  } catch (err) {
    console.log("Err: ", err);
    throw err;
  }
};

export const getAuthToken = async () => {
  return await Office.auth
    .getAccessToken({
      allowSignInPrompt: true,
      allowConsentPrompt: true,
      forMSGraphAccess: true,
    })
    .catch((err) => {
      console.log("Err: ", err);
      throw err;
    });
};

export const getAccessToken = async () => {
  try {
    let token = await OfficeRuntime.storage.getItem("accessToken");

    if (!token) {
      const newToken = await getAuthToken();
      await OfficeRuntime.storage.setItem("accessToken", newToken);
      return newToken;
    }

    let encodedPayload = token.split(".")[1];
    let tokenExpiration = JSON.parse(atob(encodedPayload))["exp"];
    let epochTimeNow = Math.floor(Date.now() / 1000);

    //call for a new token after expiration time
    if (tokenExpiration - epochTimeNow <= 0) {
      const newToken = await getAuthToken();
      await OfficeRuntime.storage.setItem("accessToken", newToken);
      return newToken;
    }

    return token;
  } catch (err) {
    console.log("Err: ", err);
    throw err;
  }
};

export const login = async () => {
  try {
    const accessToken = await getAccessToken();
    return accessToken !== null;
  } catch (err) {
    console.log("Err: ", err);
    return false;
  }
};

export const getAuthenticationHeader = async () => {
  try {
    const accessToken = await getAccessToken();

    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  } catch (err) {
    console.log("Err: ", err);
    throw err;
  }
};
