import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Button, Link } from "@fluentui/react-components";
import styled from "styled-components";
import Header from "./Header";
import Message from "./Message";
import { login } from "../utils/apiCalls";
import { ClientContext } from "./App";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: max-content 1fr;
`;

const Inner = styled.div`
  padding: 40px 18px 40px;
  display: grid;
  grid-template-rows: 1fr max-content;
  align-items: center;

  &.error {
    align-items: start;
    justify-self: center;
    max-width: 460px;
  }
`;

const MainContent = styled.div`
  justify-self: center;
  width: min-content;
  transform: translateY(-60px);
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Title = styled.div`
  margin-bottom: 24px;
  min-width: 334px;

  h1 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: center;

    span {
      white-space: nowrap;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
  }
`;

const BtnLink = styled.div`
  width: 100%;
  display: grid;
  text-align: center;

  .contact-link {
    margin: 0 3px 0 6px;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Initial = ({ setAuth }) => {
  const clientInfo = useContext(ClientContext);
  const [loginErr, setLoginErr] = useState(false);

  const handleAuthCheckClick = async () => {
    try {
      const isAuth = await login();

      if (isAuth) {
        setAuth(true);
        setLoginErr(false);
      } else {
        setAuth(false);
        setLoginErr(true);
      }
    } catch (err) {
      console.log("Err: ", err);
      setAuth(false);
      setLoginErr(true);
    }
  };

  const handleBackClick = () => {
    setLoginErr(false);
  };

  return (
    <Wrapper>
      <Header clientLogo={clientInfo.clientLogo} productLogo={clientInfo.productLogo} />

      <Inner className={loginErr ? "error" : ""}>
        {loginErr ? (
          <ErrorContent>
            <Message type="error" title="Login Failed" msgType="authErr" isMainBtn={false} />
            <BtnLink>
              <Button appearance="secondary" size="large" as="button" onClick={handleBackClick}>
                Go Back
              </Button>
              <p>
                New user?
                <Link className="contact-link" href={`mailto:${clientInfo.contact}`}>
                  Contact us
                </Link>
                to get started today!
              </p>
            </BtnLink>
          </ErrorContent>
        ) : (
          <MainContent>
            <Title>
              <h1>
                Welcome to <span>{clientInfo.title}</span>!
              </h1>
              <p>The most efficient Excel integrated enterprise grade cloud database.</p>
            </Title>
            <BtnLink>
              <Button appearance="primary" size="large" as="button" onClick={handleAuthCheckClick}>
                Start
              </Button>
              <p>
                New user?
                <Link className="contact-link" href={`mailto:${clientInfo.contact}`}>
                  Contact us
                </Link>
                to get started today!
              </p>
            </BtnLink>
          </MainContent>
        )}

        <Info>
          <p>Version 0.0.1</p>
          {/* <Link href="https://www.blubina.com/de">www.blubina.com</Link> */}
        </Info>
      </Inner>
    </Wrapper>
  );
};

Initial.propTypes = {
  setAuth: PropTypes.func,
};

export default Initial;
