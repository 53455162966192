import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  MessageBar,
  MessageBarActions,
  MessageBarTitle,
  MessageBarBody,
  Button,
  Link,
} from "@fluentui/react-components";
import styled from "styled-components";
import { ClientContext } from "./App";

const Wrapper = styled.div`
  .fui-MessageBar {
    padding-bottom: 12px;
  }
`;

const Message = ({ type, title, msgType, isMainBtn, mainBtnTxt, handleMainBtnClick }) => {
  const clientInfo = useContext(ClientContext);

  const msg = {
    loadErr: "Please load again. If the issue persists, please ",
    authErr:
      "It appears that your user does not have sufficient permissions. Please consult with your system administrator or ",
  };

  return (
    <Wrapper>
      <MessageBar layout="multiline" intent={"error"}>
        <MessageBarBody>
          <MessageBarTitle>{title}</MessageBarTitle>
          {msg[msgType]} <Link href={`mailto:${clientInfo.contact}`}>contact us</Link> for further assistance.
        </MessageBarBody>
        {isMainBtn && (
          <MessageBarActions>
            <Button onClick={handleMainBtnClick}>{mainBtnTxt}</Button>
          </MessageBarActions>
        )}
      </MessageBar>
    </Wrapper>
  );
};

Message.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  msgType: PropTypes.string,
  isMainBtn: PropTypes.bool,
  mainBtnTxt: PropTypes.string,
  handleMainBtnClick: PropTypes.func,
};

export default Message;
