import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useId, Field, Input } from "@fluentui/react-components";
import CustomLabel from "./CustomLabel";
import { Wrapper } from "./styles.common";

const FreeText = ({ name, label, valueType, defaultValue, isMandatory, selectedPara, setSelectedPara }) => {
  const inputId = useId(`para-freetext_${name}`);
  const paraName = name;
  const targetSelectedParaObj = selectedPara[paraName];

  useEffect(() => {
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { value: defaultValue, isMandatory: isMandatory, type: valueType, err: null },
    }));
  }, []);

  const handleChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { ...targetSelectedParaObj, value: trimmedValue, err: null },
    }));
  };

  return (
    <Wrapper>
      <Field
        label={<CustomLabel id={inputId} label={label} />}
        required={isMandatory}
        validationState={targetSelectedParaObj?.err ? "error" : "none"}
        validationMessage={targetSelectedParaObj?.err === "empty" ? "This field cannot be empty" : null}
      >
        <Input
          aria-labelledby={inputId}
          id={inputId}
          placeholder="..."
          value={targetSelectedParaObj?.value ? targetSelectedParaObj.value : ""}
          onChange={handleChange}
          size="large"
        />
      </Field>
    </Wrapper>
  );
};

FreeText.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  valueType: PropTypes.string,
  defaultValue: PropTypes.string,
  isMandatory: PropTypes.bool,
  selectedPara: PropTypes.object,
  setSelectedPara: PropTypes.func,
};

export default FreeText;
